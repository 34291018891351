.home {
	width: 100%;
	height: 100vh;
	background-color: antiquewhite;
	.content {
		width: 96%;
		margin-left: 2%;
		height: calc(90vh - 30px);
		margin-top: 2vh;
		box-shadow: 0px 0px 8px rgba(120, 120, 121, 0.604);
		border-radius: 1rem;
		padding: 1rem;
		.top {
			width: 100%;
			height: 8%;
		}
		.table {
			width: 100%;
			height: 92%;
		}
	}
}

@primary-color: #cfae9f;