.home {
  width: 100%;
  height: 4rem;
  background-color: #001529;
  .logo {
    width: 15%;
    height: 100%;
    float: left;
    line-height: 4rem;
    font-size: 1.5rem;
    font-weight: 600;
    color: #cfae9f;
    text-align: center;
  }
  .menu {
    width: 70%;
    height: 100%;
    float: left;
  }
  .layout {
    width: 15%;
    height: 100%;
    float: left;
    .name {
      width: 50%;
      height: 100%;
      font-size: 0.9rem;
      color: #cfae9f;
      line-height: 4rem;
      text-align: center;
      float: left;
    }
    .out {
      width: 4rem;
      height: 4rem;
      float: right;
      display: flex;
      justify-content: center;
      align-items: center;
      .lay{
        color: #cfae9f;
        font-size: 1rem;
      }
    }
    .out:hover {
      background-color: #cfae9f;

      .lay {
        color: #fff;
        font-size: 1.5rem;
      }
    }
  }
  :global {
    .ant-menu-overflow {
      height: 4rem;
      line-height: 4rem;
    }
  }
}

@primary-color: #cfae9f;