.main {
  width: 100%;
  height: 96%;
  :global {
    .ant-row {
      height: 100%;
      .ant-col {
        height: 100%;
      }
    }
  }
  .mainLeft {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: -600px 0px;

    .images {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .mainRight {
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #d9afd9;
    background-image: linear-gradient(0deg, #cfae9f 0%, #97d9e1 100%);

    .fromLogin {
      width: 100%;
      height: 20rem;
      display: flex;
      align-items: center;
      justify-content: center;
      :global {
        .ant-form {
          width: 60%;
        }
      }
    }
    .wordLogo {
      width: 100%;
      height: 28rem;
      text-align: center;
      line-height: 20rem;
      font-size: 5rem;
      font-weight: 600;
      color: #cfae9f;
      font-family:'Times New Roman', Times, serif;
    }
  }
}

@primary-color: #cfae9f;